<template>
  <v-dialog
    v-model="show"
    max-width="700px"
    scrollable
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          Respostas
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon
              v-text="'mdi-close'"
            />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text >

        <template v-if="loading">
          <v-skeleton-loader
            v-for="index in 5"
            class="mx-auto mb-3"
            type="paragraph"
            :key="index"
          ></v-skeleton-loader>
        </template>

        <v-list
          v-else
          two-line
        >
          <v-list-item
            v-for="answer in answers"
            class="pa-0"
            :key="answer.id"
          >
            <v-list-item-content>
              <v-list-item-title class="wrap-text display-1 font-weight-bold">
                  {{ answer.question.title }}
              </v-list-item-title>
              <v-list-item-content class="pt-0 body-1 font-weight-light">
                <template v-if="answer.questionItem">
                  {{ answer.questionItem.title }}
                </template>

                <template v-if="answer.questionItem && answer.questionItem.explain && answer.text">
                  <br>
                </template>

                <template v-if="answer.text && answer.question.typeOfQuestion.key !== 'TABLE'">
                  {{ answer.text }}
                </template>

                <template v-if="answer.text && answer.question.typeOfQuestion.key === 'TABLE'">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th></th>
                          <th
                            v-for="(column, index) in JSON.parse(answer.question.schema).columns" 
                            class="text-left"
                            :key="index"
                          >
                            {{ column.title }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(row, rowIndex) in JSON.parse(answer.question.schema).rows"
                          :key="rowIndex"
                        >
                          <td class="title">{{ row.title }}</td>
                          <td
                            v-for="(v, columnIndex) in JSON.parse(answer.question.schema).columns.length"
                            :key="columnIndex"
                            class="py-2"
                          >
                            {{ JSON.parse(answer.text)[`${rowIndex}-${columnIndex}`] }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
              </v-list-item-content>
            </v-list-item-content>
          </v-list-item>
        </v-list>

      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import surveysAnswersApi from '@/api/surveys-answers'

  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      surveyId: {
        type: Number,
        default: 0,
      },
      contactId: {
        type: Number,
        default: 0,
      },
    },

    data () {
      return {
        loading: false,
        answers: []
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    mounted() {
      this.loadAnswers()
    },

    methods: {
      async loadAnswers () {
        try {
          this.loading = true

          const response = await surveysAnswersApi.listAnswersByContact(this.surveyId, this.contactId)

          this.answers = response.data.answers
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      }
    },

  }
</script>

<style>
.wrap-text {
  white-space: normal;
}
</style>